<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Pencabutan SIP">
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start">
            <!-- empty -->
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <span class="align-middle">Ajukan Pencabutan SIP</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'tgl_pencabutan'">
              {{ props.row.tgl_pencabutan.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'tgl_pengajuan'">
              {{ props.row.tgl_pengajuan.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <span v-else-if="props.column.field === 'status_pencabutan'">
              <span v-if="props.row.status_pencabutan === false">
                <b-badge variant="warning"> Belum Diverifikasi </b-badge>
              </span>

              <span v-if="props.row.status_pencabutan === true">
                <b-badge variant="success"> Sudah Diverifikasi </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'catatan_pencabutan'">
              <span v-if="props.row.catatan_pencabutan === 'None'"> - </span>

              <span v-else>
                {{ props.row.catatan_pencabutan }}
              </span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <!-- <div class="demo-inline-spacing"> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-tooltip.hover.v-success
                title="Lihat Dokumen"
                class="btn-icon mr-2"
                @click="showModalBerkas(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.v-primary
                title="Download Surat Pencabutan SIP"
                class="btn-icon"
                @click="downloadPDF(props)"
              >
                <feather-icon icon="DownloadIcon" class="cursor-pointer" />
              </b-button>

              <!-- <b-button
                    v-if="!props.row.nama_berkas.length"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-b-tooltip.hover.v-success
                    title="Lihat Dokumen"
                    class="btn-icon"
                    @click="showModalBerkasNull(props)"
                  >
                    <feather-icon icon="SearchIcon" class="cursor-pointer" />
                  </b-button> -->

              <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Upload Berkas Pencabutan"
                    class="btn-icon"
                    @click="showModalUpload(props)"
                  >
                    <feather-icon icon="UploadIcon" class="cursor-pointer" />
                  </b-button> -->

              <!-- <b-button
                    v-if="props.row.status_pencabutan === false"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-tooltip.hover.v-warning
                    title="Ubah Data Pencabutan SIP"
                    class="btn-icon"
                    @click="showModalEdit(props)"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>
  
                  <b-button
                    v-if="props.row.status_pencabutan === true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-tooltip.hover.v-warning
                    title="Ubah Data Pencabutan SIP"
                    class="btn-icon"
                    @click="showModalEditCannot(props)"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button> -->

              <!-- </div> -->
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      combo_profesi: [],
      combo_syarat: [],

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "ID Pencabutan",
          field: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "ID SIP Yang Akan Dicabut",
          field: "id_sip",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Tanggal Pengajuan",
          field: "tgl_pengajuan",
          thClass: "text-center",
        },
        {
          label: "Tanggal Pencabutan",
          field: "tgl_pencabutan",
          thClass: "text-center",
        },
        {
          label: "Status",
          field: "status_pencabutan",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "catatan_pencabutan",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "160px",
        },
      ],

      rows: [],
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_sip: "",

      created_date: "",
      email: "",
      id_pendaftar: "",

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search1 = "";
      if (this.created_date) {
        search1 = "created_date=" + this.created_date + "&";
      } else {
        search1;
      }

      return new Promise(() => {
        axios
          .get(
            "/pencabutan_sip/get_all_pencabutan_sip?id_pendaftar=" +
              localStorage.getItem("id_pendaftar") +
              "&" +
              search1 +
              "page=" +
              page +
              "&size=" +
              size
          )
          .then((res) => {
            this.rows = res.data.content.data;

            this.paging.page = res.data.content.page;
            this.paging.size = res.data.content.size;
            this.paging.total_elements = res.data.content.total_row_filtered;
            this.paging.total_pages = res.data.content.total_page;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalTambah() {
      this.$router.push("/pencabutan-sip/tambah");
    },

    showModalEditCannot() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title:
            "Ubah Data Pencabutan SIP Hanya Bisa Jika Status Belum Diverifikasi",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    showModalEdit(props) {
      this.$router.push("/pencabutan-sip/" + props.row.id + "/ubah");
    },

    showModalUpload(props) {
      this.formSyarat.id_pendaftar = props.row.id_pendaftar;
      this.formSyarat.id_sip = props.row.id;
      this.formSyarat.id_profesi = props.row.id_profesi;

      return new Promise(() => {
        axios
          .get(
            "pendaftar/berkas_persyaratan?id_profesi=" + props.row.id_profesi
          )
          .then((res) => {
            this.combo_syarat = res.data;

            this.$refs["ref-payment-upload"].show();
          });
      });
    },

    handleImage() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formSyarat.data_url = rawImg;
      };
      reader.readAsDataURL(file);
    },

    showModalBerkas(props) {
      this.$router.push(
        "/pencabutan-sip/" +
          props.row.id_pendaftar +
          "/" +
          props.row.id +
          "/detail"
      );
    },

    showModalBerkasNull() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "File Dokumen Belum Ada Yang Terupload",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    pencabutanSIP(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text:
          "Untuk Melakukan Pencabutan SIP atas nama " + props.row.nama + " ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Cabut SIP",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.pencabutanSIPApi(props);
        }
      });
    },

    pencabutanSIPApi(props) {
      return new Promise(() => {
        axios
          .put("admin/cabut_sip?id_sip=" + props.row.id, this.formCabutSIP)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Pencabutan SIP Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Pencabutan SIP Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("pendaftar/delete_sip?id_sip=" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data SIP Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    downloadPDF(props) {
      window.open(
        "https://sipolnakes.salatiga.go.id/api/v1/pencabutan_sip/download_bukti_pencabutan_sip_pemohon?id_pencabutan=" +
          props.row.id
      );
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.modal-header .close {
  display: none;
}
</style>
